import React from "react";
import {
  useLogin,
  LoadingScreen,
  AuthProvider,
  Header,
  HeaderItem,
  Footer,
  NotFoundScreen,
} from "@hex-labs/core";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { setPersistence, getAuth, inMemoryPersistence } from "firebase/auth";
import { Link, Route, Routes } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Table from "./components/Tables/Table";
import EditEntry from "./components/Tables/EditEntry";

export const app = initializeApp({
  apiKey: "AIzaSyCsukUZtMkI5FD_etGfefO4Sr7fHkZM7Rg",
  authDomain: "auth.hexlabs.org",
});
setPersistence(getAuth(app), inMemoryPersistence);

axios.defaults.withCredentials = true;

export const App = () => {
  const [loading, loggedIn] = useLogin(app);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!loggedIn) {
    window.location.href = `https://login.hexlabs.org?redirect=${window.location.href}`;
  }

  return (
    <AuthProvider app={app}>
      <Header>
        <Link to="/">
          <HeaderItem>Home</HeaderItem>
        </Link>
        <Link to="/events">
          <HeaderItem>Events</HeaderItem>
        </Link>
        <Link to="/locations">
          <HeaderItem>Locations</HeaderItem>
        </Link>
        <Link to="/tags">
          <HeaderItem>Tags</HeaderItem>
        </Link>
      </Header>
      <Routes>
        <Route index element={<Dashboard/>} />
        <Route path="/events" element={<Table name="Events"/>} />
        <Route path="/events/:id" element={<EditEntry name="Events"/>} />
        <Route path="/locations" element={<Table name="Locations"/>} />
        <Route path="/locations/:id" element={<EditEntry name="Locations"/>} />
        <Route path="/tags" element={<Table name="Tags"/>} />
        <Route path="/tags/:id" element={<EditEntry name="Tags"/>} />
        <Route path="/*" element={<NotFoundScreen/>} />
      </Routes>
      <Footer />
    </AuthProvider>
  );
};
