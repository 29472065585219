/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { apiUrl, ErrorScreen, SearchableTable, Service } from "@hex-labs/core";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  IconButton,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Spacer,
  Text,
  useDisclosure
} from "@chakra-ui/react"
import { AddIcon, CloseIcon, ViewIcon } from "@chakra-ui/icons";
import axios from "axios";
import { Link } from "react-router-dom";

import Columns from "./Columns";
import EventFormInput from "./FormInputs/EventFormInput";
import TagFormInput from "./FormInputs/TagFormInput";
import LocationFormInput from "./FormInputs/LocationFormInput";

interface Props {
  name: string;
}

const Table: React.FC<Props> = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState();
  const [columns, setColumns] = useState(Columns[props.name].filter((column: any) => column.enabled))

  useEffect(() => {
    const getData = async() => {
      document.title = props.name.concat(" – HexLabs Schedule");

      let hexathonRes: any = null;

      try {
        if (props.name !== "Locations") {
          hexathonRes = await axios.get(apiUrl(Service.HEXATHONS, "/hexathons"));
        }

        const res = await axios.get(
          apiUrl(Service.HEXATHONS, `/${props.name.toLowerCase()}`),
          { params: { search: searchText } }
        );

        const temp: any[] = []
        res.data.forEach((entry: any) => {
          temp.push({
            ...entry,
            hexathon: hexathonRes?.data.filter((hexathon: any) => hexathon.id === entry.hexathon)[0].name,
            location: entry.location?.map((location: any, index: number) => (
              <>
                <ChakraLink as={Link} to={`/locations/${location.id}`}>{location.name}</ChakraLink>
                {(index === entry.location.length - 1) ? "" : ", "}
              </>
            )),
            tags: entry.tags?.map((tag: any, index: number) => (
              <>
                <ChakraLink as={Link} to={`/tags/${tag.id}`}>{tag.name}</ChakraLink>
                {(index === entry.tags.length - 1) ? "" : ", "}
              </>
            )),
          })
        })

        setData(temp);
      } catch(e: any) {
        setError(e);
      }
      
      setColumns(Columns[props.name].filter((column: any) => column.enabled));
    }

    getData();
  }, [isOpen, props.name, searchText]);

  if (error) {
    return <ErrorScreen error={error} />;
  }

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <Box
        margin="auto"
        marginTop="20px"
        width={{
          base: "90%",
          md: "80%"
        }}
      >
        <HStack>
          <Heading>
            {props.name}
          </Heading>
          <Spacer/>
          <HStack spacing="5px">
            {
              props.name === "Events" ? (
                <Menu
                  closeOnSelect={false}
                >
                  <MenuButton
                    marginRight="10px"
                    as={IconButton}
                    bg="transparent"
                    minWidth="48px"
                    height="48px"
                    isRound
                    padding="0px"
                    textAlign="center"
                    verticalAlign="center"
                  >
                    <ViewIcon width="1.5em" height="1.5em"/>
                  </MenuButton>
                  <MenuList zIndex="999">
                    <MenuOptionGroup
                      width="100%"
                      type='checkbox'
                      onChange={(e: any) => setColumns(e.sort((a: any, b: any) => a.key - b.key))}
                      value={columns}
                    >
                      {Columns[props.name].map((column: any) => (
                        <MenuItemOption key={column.key} value={column}>{column.header}</MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              ) : null
            }
            <Button
              width={{
                base: "48px",
                md: "100%",
              }}
              height="48px"
              borderRadius={{
                base: "24px",
                md: "0.375rem"
              }}
              onClick={onOpen}
            >
              <HStack spacing={{
                base: "0px",
                md: "5px"
              }}>
                <AddIcon width="0.8em" height="0.8em"/>
                <Text
                  display={{
                    base: "none",
                    md: "block"
                  }}
                >
                  Create {props.name.substring(0, props.name.length - 1)}
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
        <SearchableTable
          title=""
          data={data}
          columns={columns}
          searchText={searchText}
          onSearchTextChange={handleSearchChange}
        />
      </Box>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        autoFocus={false}
        returnFocusOnClose={false}
        size="md"
        closeOnOverlayClick
        closeOnEsc
      >
        <DrawerOverlay display='none'/>
        <DrawerContent>
          <DrawerHeader borderBottom="2px" borderColor="rgba(23, 43, 77, 0.12)">
            <HStack>
              <Heading marginTop="10px" fontSize="24px">
                Create {props.name.substring(0, props.name.length - 1)}
              </Heading>
              <Spacer/>
              <IconButton
                aria-label="Close Button"
                isRound
                icon={<CloseIcon width="0.75em" height="0.75em"/>}
                bg="transparent"
                onClick={onClose}
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody paddingTop="20px">
            {
              (props.name === "Events") ? (
                <EventFormInput onClose={onClose}/>
              ) : (props.name === "Locations") ? (
                <LocationFormInput onClose={onClose}/>
              ) : (props.name === "Tags") ? (
                <TagFormInput onClose={onClose}/>
              ) : null
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default Table;