import React from "react";
import { Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

export const Types: {[key: string]: string} = {
  "food": "Food",
  "workshop": "Workshop",
  "ceremony": "Ceremony",
  "tech-talk": "Tech Talk",
  "mini-event": "Mini Event",
  "important": "Important",
  "speaker": "Speaker",
  "mini-challenge": "Mini Challenge",
}

const eventColumns = [
  {
    key: 0,
    enabled: true,
    header: "Name",
    field: "name",
    accessor: (row: any) => <ChakraLink as={Link} to={`/events/${row.id}`}>{row.name}</ChakraLink>,
  },
  {
    key: 1,
    enabled: true,
    header: "Hexathon",
    field: "hexathon",
    accessor: (row: any) => row.hexathon,
  },
  {
    key: 2,
    enabled: false,
    header: "Start Date",
    field: "startDate",
    accessor: (row: any) => dateFormat(row.startDate, "mm/dd/yyyy"),
  },
  {
    key: 3,
    enabled: true,
    header: "Start Time",
    field: "startTime",
    accessor: (row: any) => dateFormat(row.startDate, "hh:MM TT"),
  },
  {
    key: 4,
    enabled: false,
    header: "End Date",
    field: "endDate",
    accessor: (row: any) => dateFormat(row.endDate, "mm/dd/yyyy"),
  },
  {
    key: 5,
    enabled: true,
    header: "End Time",
    field: "endTime",
    accessor: (row: any) => dateFormat(row.endDate, "hh:MM TT"),
  },
  {
    key: 6,
    enabled: true,
    header: "Location",
    field: "location",
    accessor: (row: any) => row.location,
  },
  {
    key: 7,
    enabled: true,
    header: "Type",
    field: "type",
    accessor: (row: any) => Types[row.type],
  },
  {
    key: 8,
    enabled: false,
    header: "Description",
    field: "description",
    accessor: (row: any) => row.description,
  },
  {
    key: 9,
    enabled: false,
    header: "Tags",
    field: "tags",
    accessor: (row: any) => row.tags,
  },
];

const locationColumns = [
  {
    key: 0,
    enabled: true,
    header: "Name",
    field: "name",
    accessor: (row: any) => <ChakraLink as={Link} to={`/locations/${row.id}`}>{row.name}</ChakraLink>,
  }
];

const tagColumns = [
  {
    key: 0,
    enabled: true,
    header: "Hexathon",
    field: "hexathon",
    accessor: (row: any) => row.hexathon,
  },
  {
    key: 1,
    enabled: true,
    header: "Name",
    field: "name",
    accessor: (row: any) => <ChakraLink as={Link} to={`/tags/${row.id}`}>{row.name}</ChakraLink>,
  }
];

type StringToArray = { 
  [name: string]: any[] 
};

const Columns: StringToArray = {
  "Events": eventColumns,
  "Locations": locationColumns,
  "Tags": tagColumns
}

export default Columns;