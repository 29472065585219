import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spacer,
} from "@chakra-ui/react";
import { apiUrl, ErrorScreen, Service } from "@hex-labs/core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form"

import { locationResolver } from "../Resolvers";
import { LocationFormValues } from "../FormValues";

interface Props {
  id?: string;
  onClose?: () => void;
}

const LocationFormInput: React.FC<Props> = ({id, onClose}) => {
  const navigate = useNavigate();
  const [error, setError] = useState();
  const [errors, setErrors] = useState<{[field: string]: any}>({});
  const {
    register,
    reset,
    getValues,
  } = useForm<LocationFormValues>({
    resolver: locationResolver,
  });

  useEffect(() => {
    const getData = async() => {
      try {
        const res = await axios.get(apiUrl(Service.HEXATHONS, "/locations"));
        const data = res.data.filter((entry: any) => entry.id === id)[0];

        if (data)
          reset({...data});
      } catch(e: any) {
        setError(e);
      }
    }

    getData();
  }, [id, reset]);

  const findMissingField = (data: any) => {
    const missingRequiredFieldError = {
      name: (!data.name || data.name.length === 0) ? ({
        type: "required",
        message: "Location name is required."
      }) : undefined,
    }

    const missingRequired = (!data.name || data.name.length === 0);

    if (missingRequired) {
      setErrors({...missingRequiredFieldError});
      return 0;
    }
    return 1;
  }

  const submit = async (data: any) => {   
    const payload: {[name: string]: any} = {
      ...data,
    }

    let res = null;
    try {
      if (id) {
        res = await axios.put(apiUrl(Service.HEXATHONS, `/locations/${id}`), payload)
      } else {
        res = await axios.post(apiUrl(Service.HEXATHONS, "/locations"), payload);
      }
      
      if (res.status >= 200) {
        navigate(-1);
        if (onClose) onClose();
      }
    } catch(e: any) {
      setErrors({ request: JSON.parse(e.request.response) })
    }
  }

  const cancel = () => {
    navigate(-1);
    if (onClose) onClose();
  }

  const del = async () => {
    const res = await axios.delete(apiUrl(Service.HEXATHONS, `/locations/${id}`));
    if (res.status >= 200) {
      navigate(-1);
      if (onClose) onClose();
    }
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <form>
      <FormControl
        isInvalid={errors.name}
        marginBottom={errors.name ? "12px" : "42px"}
      >
        <FormLabel>Name</FormLabel>
        <Input
          id='name'
          placeholder="Location Name"
          {...register("name")}
        />
        <Box marginTop="6px" color="red">
          {(errors.name) ? errors.name.message : null}
        </Box>
      </FormControl>
      <Box height="48px" paddingY="12px" color="red">
        {errors.request && errors.request.message}
      </Box>
      <HStack
        height="10%"
        width="100%"
        bg="white"
      >
        <Button
          onClick={() => { if (findMissingField(getValues())) submit(getValues()) }}
        >
          {id ? "Update" : "Submit"}
        </Button>
        <Button onClick={cancel}>
          Cancel
        </Button>
        <Spacer/>
        {
          id ? (
            <Button bg="red.400" color="white" onClick={del}>
              Delete
            </Button>
          ) : null
        }
      </HStack>
    </form>
  )
}

export default LocationFormInput;